// variables
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1200px
);

$enable-shadows: true;
$header-max-width: 1440;
$page-max-width: 1170px;

$theme-blue-dark: #002C57;
$theme-blue-light: #2A89C5;
$theme-blue-lightest: #C1D6EB;
$theme-grey-dark: #565872;
$theme-grey-light: #EEF0F4;
$theme-red: #FF6363;
$theme-red-light: #FBE4E4;

$primary: $theme-blue-light;
$primary-dark: $theme-blue-dark;
$primary-light: lighten($primary, 36%);
$secondary: $theme-grey-dark;
$secondary-light: lighten($secondary, 62%);

$bg: #FFFFFF;

$theme-colors: () !default;
$theme-colors: map-merge((
  "primary-dark": $primary-dark
), $theme-colors);

$body-bg: $bg;
$body-color: $theme-grey-dark;
$link-color: $theme-blue-light;
$link-hover-color: darken($link-color, 5%);
$link-hover-decoration: none;
$box-shadow: 0 .125rem 1.25rem rgba(black, .15); //0 2px 20px 0 rgba(0, 0, 0, 0.15);
$box-shadow-light: 0 2px 4px 0 rgba(0, 0, 0, 0.02);

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-color: $primary-dark;
$headings-font-family: 'Open Sans', sans-serif;
$headings-font-weight: 700;

//$border-radius: 2px;
//$border-radius-lg: 2px;
//$border-radius-sm: 2px;
//
//$btn-border-radius: .25rem;
//$btn-border-radius-lg: .3rem;
//$btn-border-radius-sm: .2rem;

$popover-header-padding-x: 1rem;
$popover-border-color: transparent;
$popover-border-radius: 4px;
$popover-box-shadow: $box-shadow;

$navbar-padding-x: 0;
$navbar-padding-y: 0.975rem;
$navbar-nav-link-padding-x: 0;

$navbar-light-color: rgba($primary-dark, 1);
$navbar-light-hover-color: rgba($primary-dark, .7);
$navbar-light-active-color: rgba($primary-dark, .7);
$navbar-light-brand-color: $primary-dark;

$nav-tabs-link-active-color: black;
$nav-tabs-link-active-bg: #FFF;

$card-cap-bg: #FFF;

$spacer: 1rem;
$spacers: (); // add more spacer classes
$spacers: map-merge(
                (
                        5: ($spacer * 2),
                        6: ($spacer * 3),
                        7: ($spacer * 4),
                        8: ($spacer * 5),
                        9: ($spacer * 6)
                ),
                $spacers
);

$card-border-radius: 1px;
$card-border-color: transparent;
$card-border-color-header: transparent;
$card-spacer-x: 1.125rem;

//$modal-content-border-radius: $border-radius;
$modal-header-border-color: #FFF;
$modal-footer-border-color: #FFF;
$modal-content-border-color: #FFF;
$modal-content-box-shadow-xs: $box-shadow;
$modal-content-box-shadow-sm-up: $box-shadow;

$carousel-indicator-width: 9px;
$carousel-indicator-height: 9px;
$carousel-indicator-active-bg: #b5b5b5;

$pagination-color: $link-color;
$pagination-bg: #FFF;
$pagination-border-color: #FFF;

$pagination-active-color: #FFF;
$pagination-active-bg: $secondary;
$pagination-active-border-color: $secondary;

$badge-font-size: 1rem;
//$badge-border-radius: $btn-border-radius;

//$input-placeholder-color: $theme-grey-light;

// imports
@import "bootstrap.scss";
@import "font-awesome.scss";
@import "loader.scss";
@import "select2.scss";
@import "widget.scss";
@import "circle";
@import "~datatables.net-bs4/css/dataTables.bootstrap4";
@import "~datatables.net-responsive-bs4/css/responsive.bootstrap4";
@import "fonts";

// Generate font-size utility classes.
// Must be defined after bootstrap imports.
// .font-size-{value} for xs
// .font-size-{breakpoint}-{value} for sm, md, lg, and xl.
// eg .font-size-sm-14
$font-sizes: (
        10: 0.625rem,
        11: 0.688rem,
        12: 0.75rem,
        13: 0.813rem,
        14: 0.875rem,
        15: 0.9375rem,
        16: 1rem,
        18: 1.125rem,
        20: 1.250rem,
        22: 1.375rem,
        24: 1.5rem,
        30: 1.875rem,
        32: 2rem,
        38: 2.375rem,
        48: 3rem,
        60: 3.75rem
);
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $key, $value in $font-sizes {
      .font-size#{$infix}-#{$key} {
        font-size: $value;
      }
    }
  }
}

/**
 Generate utility classes for backgrounds, texts and badges (bg-color, text-color, badge-color).
 If we put these colors to the theme-colors map, the full stack of bootstrap helper classes is created.
 (buttons, tables, alerts, list-groups).
 */
$theme-utility-colors: () !default;
$theme-utility-colors: map-merge((), $theme-utility-colors);

@each $color, $value in $theme-utility-colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}

@each $color, $value in $theme-utility-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

@each $color, $value in $theme-utility-colors {
  @include bg-variant(".bg-#{$color}", $value);
}

@if $enable-gradients {
  @each $color, $value in $theme-utility-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
  }
}

// Generate border-left utility classes foreach theme color
// Must be defined after bootstrap imports.
// eg .bl .bl-secondary
.bl {
  border-left: 10px solid $primary;
}

@each $color, $value in $theme-colors {
  .bl-#{$color} {
    @extend .bl;
    border-color: $value !important;
  }
}

body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

.container-fluid {
  max-width: $page-max-width;
}

.container-fluid--header {
  max-width: $header-max-width;
}

.container-child {
  margin-left: -$grid-gutter-width * .5;
  margin-right: -$grid-gutter-width * .5;
}

.row--cols-equal-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row--cols-equal-height > [class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

#notifications {
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: $page-max-width;
  z-index: 2000;
}

.white-space-pre {
  white-space: pre-line;
}

.actionbar {
  width: 100%;
  @extend .py-5;
  @extend .bg-secondary;
}

h1 > a, h1 > a:hover,
h2 > a, h2 > a:hover,
h3 > a, h3 > a:hover,
h4 > a, h4 > a:hover,
h5 > a, h5 > a:hover,
h6 > a, h6 > a:hover {
  color: $headings-color;
}

.clickable {
  cursor: pointer;
}

.font-family-heading {
  font-family: $headings-font-family;
}

.font-family-body {
  font-family: $font-family-sans-serif;
}

.collapse-toggle {
  @extend .clickable;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .7);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay {
  position: relative;
}

.overlay > * {
  z-index: 2
}

// Carousel

.carousel {
  margin-bottom: 5rem;
}

.carousel-indicators {
  bottom: -3.5rem;
}

.carousel-indicators > li {
  border-radius: 50%;
}

.carousel-item--campaign {
  height: auto;
}

@include media-breakpoint-up(sm) {
  .carousel-item--campaign {
    height: 476px;
  }
}

// Tabs

.nav-tabs .nav-link {
  background-color: $bg;
}

// Pagination

.page-link,
.page-item.disabled .page-link,
.page-item:first-child .page-link,
.page-item:last-child .page-link {
  background-color: $bg;
  border-color: $bg;
  margin-left: .2rem;
  margin-right: .2rem;
  border-top-left-radius: 5px; // have to be defined foreach corner to override bootstrap styles
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}


// Drag & drop

.drag-handle {
  cursor: move
}

@include media-breakpoint-down(sm) {
  .page-item.ks-boundary-first,
  .page-item.ks-boundary-last {
    display: none;
  }
}

#header {
  margin-top: 1rem;
}

@include media-breakpoint-up(md) {
  #header {
    margin-top: 3rem;
  }
}

.navbar-nav .nav-item .nav-link {
  @extend .px-3;
  //@extend .font-size-16;
  font-weight: 600;
}

.navbar-light .navbar-nav .nav-link.active-path,
.navbar-light .navbar-nav .nav-link.active-path:focus,
.navbar-light .navbar-nav .nav-link.active-path:active,
.navbar-light .navbar-nav .nav-link.active-path:hover,
.navbar-light .navbar-nav .nav-link:hover {
  color: $primary !important;
}

.col--search-form {
  position: relative;
  top:100px;
  margin-bottom: 100px;
  @extend .bg-white;
  @extend .pt-5;
  @extend .pb-7;
  border-top: 8px solid $primary;
}

.search-form-wrapper {
  position: relative;
  top: -1rem;
  @extend .bg-white;
  @extend .pt-3;
  @extend .pb-3;
  border-top: 1rem solid $primary;
  box-shadow: 0px 10px 30px rgba(#000000, .05);
  z-index: 3;
}

@include media-breakpoint-up(sm) {

  .search-form-wrapper {
    top: -2.2rem;
    @extend .pt-sm-5;
    @extend .pb-sm-5;
  }

  .col--search-form {
    min-width: 90%;
  }

  #search-term {
    position: relative;
    left:1px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #search-term-location {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left-color: transparent;
  }
}

@include media-breakpoint-up(lg) {

  .search-form-wrapper {
    top: -3.5rem;
    margin-left: 3rem;
    margin-right: 3rem;
    @extend .pt-lg-5;
    @extend .pb-lg-5;
  }
}

.row--full-width {
  position: relative;
  min-height: 10px;
  z-index: 1;
}

// see https://css-tricks.com/full-width-containers-limited-width-parents/
.row--full-width:before {
  content: "";
  position:absolute;
  display: block;
  width: 100vw;
  height:100%;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;

}

.row--full-width-hero:before {
  background-image: url("../images/banner-neurotreff.jpg");
  background-repeat: repeat-x;
  //background-position: center 100%;
  background-size: auto 100%;
  background-position: center bottom;
}

.row--full-width-hero--docview:before {
  background-image: url("../images/banner-xeomotion-bg.jpg");
  background-position: center bottom;
}

@include media-breakpoint-up(md) {
  .row--full-width-hero:before {
    //background-position: center 90%;
  }
}

@include media-breakpoint-up(lg) {
  .row--full-width-hero:before {
    //background-position: center bottom;
  }
}

.row--full-width-hero:after {
  content: "";
  position: relative;
  display: block;
  padding-bottom: 33%;
  width: 100%;
  height:100%;
}

.row--full-width-hero--docview:after {
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100%;
  background-image: url("../images/banner-xeomotion.jpg");
}

.row--full-width-primary,
.row--full-width-primary:before {
  background-color: $primary-dark;
}

.bg-grey-light,
.row--full-width-grey,
.row--full-width-grey:before {
  background-color: $theme-grey-light;
}

.hero-text {
  position: absolute;
  bottom: 50%;
  text-align: center;
  font-size:1.2rem;
  font-weight: bold;
  z-index: 2;
  color: white;
}

@include media-breakpoint-up(sm) {
  .hero-text {
    bottom: 4.5rem;
    font-size: 2rem;
  }
}

@include media-breakpoint-up(md) {
  .hero-text {
    font-size: 3rem;
  }
}

@include media-breakpoint-up(lg) {
  .hero-text {
    font-size: 4rem;
  }
}

#map {
  height: 300px;
}

.search-result-image {
  @extend .pb-3;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: .3rem;
  padding-bottom: .3rem;
  border-color: #EBEBEB;
  border-style: solid;
  border-width: 0 0 1px 1px;
  border-radius: 0 0 0 5px;
}

.search-result-details {
  @extend .py-4;
  @extend .pl-3;
  border-color: #EBEBEB;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  border-radius: 5px 5px 0 0;
}


.search-result-icons {
  @extend .pt-1;
  @extend .pb-4;
  @extend .pl-3;
  border-color: #EBEBEB;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-radius: 0 0 5px 0;
}

@include media-breakpoint-up(sm) {

  .search-result-image {
    @extend .pb-sm-4;
    min-height:234px;
    min-width: 135px;
    padding-top: 1.3rem;
    padding-bottom: 1.3rem;
    border-width: 1px 0 1px 1px;
    border-radius: 5px 0 0 5px;
  }

  .search-result-details {
    border-width: 1px 0 1px 0;
    border-radius: 0 0 0 0;
  }


  .search-result-icons {
    @extend .py-sm-4;
    @extend .pl-sm-3;
    border-width: 1px 1px 1px 1px;
    border-radius: 0 5px 5px 0;
  }

}

.search-result-icon {
  margin-bottom: .4rem;
  padding-left: 18px;
  position: relative;
  @extend .font-size-12;
  @extend .text-truncate
}

.search-result-icon .fa {
  position: absolute;
  left: 0;
  top: 3px;
}

.search-result-icon--location {
  margin-bottom: .1rem;
}

.search-section-header,
.filter-box-header {
  background-color: $theme-blue-lightest;
  border-radius: 5px;
  color: $primary-dark;
  @extend .py-3;
}

.filter-box {
  border: 1px solid #EBEBEB;
  border-top-width: 0;
  border-radius: 5px;
  @extend .mb-5;

}

.filter-box-header {
  @extend .px-3;
}

.filter-box-content {
  @extend .py-4;
  @extend .px-3;
}

.info-box {
  border: 1px solid #EBEBEB;
  position: relative;
  padding-top: 70px;
  @extend .px-3;
  min-height: 200px;
  margin-top: 70px;
  box-shadow: 0px 8px 24px rgba(#000000, .2);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.info-box h1,
.info-box h2,
.info-box h3 {
  @extend .mt-3;
  @extend .font-size-18;
}


.info-box-icon {
  position: absolute;
  box-shadow: 0px 3px 16px #0000000F;
  border-radius: 5px;
  width: 95px;
  height: 95px;
  top: -40px;
  left: 20px;
  background-image: url("../images/bg_kristall.png");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-box-icon-xeomin {
  display: inline-block;
  width: 95px;
  height: 95px;
  background-image: url("../images/bg_kristall-xeomin.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.info-box-icon > .fa {
  color: white;
  @extend .font-size-24;
}

.info-box-icon-step-1 {
  background-image: url("../images/icon-step-1.gif");
}

.info-box-icon-step-2 {
  background-image: url("../images/icon-step-2.gif");
}

.info-box-icon-step-3 {
  background-image: url("../images/icon-step-3.gif");
}

.info-box-text {
  min-height: 70px;
  flex-grow: 2;
}

img {
  max-width: 100%;
}

.about-wrapper {
  position:relative;
  top:-5rem;
  max-width:90%;
  margin-left: auto;
  margin-right: auto;
  z-index:10;
}

@include media-breakpoint-up(md) {
  .about-wrapper {
    max-width:70%;
  }
}

.initially-hidden {
  display: none;
}

.tab--active .link-more {
  display: none;
}

.tab--active .link-less {
  display: inline-block;
}

.tab--inactive .link-more {
  display: inline-block;
}

.tab--inactive .link-less {
  display: none;
}

.tab--active {
  border: 1px solid $primary;
}

.tab--active.no-outline {
  border: none;
}

.validationMessage {
  @extend .font-size-14;
  @extend .text-danger;
}

.btn-bar {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}

.knockout-autocomplete.after-menu {
  z-index: 1000;
  width: calc(100% - 10px);
  border: thin #aaa solid;
}

.badge-light-outline {
  background-color: #e9ecef !important;
  border: 1px solid #565872;
}

.form__label--dummy {
  display: block;
}

// set the height as a percentage of the width. padding bottom defines the percentage of the height.
.profile_image-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 150%;
  background-color:#CCC;
  z-index: 98;
}

.profile_image {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../images/person-dummy.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: 99;
}

.podcast-image {
  max-width: 100px;
  padding-bottom: 1rem;
}

.faq-section {
  margin-bottom: 2.5rem;
}

.links-section {
  margin-bottom: 2.5rem;
}

.accordion .card-header {
  background-color: $theme-grey-light;
}

#accordion-xeomin .card-body h4:not(:first-child) {
  @extend .mt-5;
}

#accordion-xeomin .card-body h5:not(:first-child) {
  @extend .mt-4;
}

.th--border-bottom {
  border-bottom: 2px solid $table-border-color;
}

.th--special {
  font-weight: bold;
  @extend .th--border-bottom
}

div[id^="tab-content"] {
  scroll-margin-top: 1rem;
}


.fa-icon::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: FontAwesome;
}

.fa-icon--link-ext:before {
  content: "\f08e";
  margin-right: 3px;
  margin-left: 2px;
  position: relative;
  top:2px;
}

.label-marker-icon {
  max-height: 17px;
  position: relative;
  top:-1px;
}

.accordion {
  .card {
    border: 1px solid rgba(0,0,0,.125);
  }

  .card-header {
    padding: 0.75rem 1.25rem;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125)
  }

  .card-body {
    padding: 1.25rem;
  }

}

.logos {
  background-image: url("../images/neurotreffpunkt-logo-subline.png"), url("../images/xeomotion-logo-subline.png");
}

.spacer {
  display: block;
  @extend .mb-7;
}





