$btn-padding-y-xs: 1px;
$btn-padding-x-xs: 2px;
$btn-font-size-xs: $font-size-base * 1;
$btn-line-height-xs: 1;
$btn-border-radius-xs: .1rem;

.btn-xs {
  @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}

.widget-admin-btn-bar {
  margin-top: 1rem;
}

.widget-container {
  //margin-left: -($grid-gutter-width / 2);
  //margin-right: -($grid-gutter-width / 2);
}

.widget-container, .widget-page {
  border: 0 solid transparent;
}

.widget-container > .card-body {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.widget-page {
  margin-top: 1.5rem;
}

.widget-container {
  margin-bottom: 1.2rem;
}

.widget-placeholder--editing {
  margin-top: 1.2rem;
  border: 2px dashed $secondary;
  border-radius: 5px;
}

.widget-edit-bar {
  text-align: right;
  opacity: .5;
  position: relative;
  top: -4px;
}

.widget-icons-top {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1000;
  opacity: 0.5
}

.widget-nav-bar {
  margin-top: 1.2rem;
  margin-bottom: 2rem;
}

.opacity-50 {
  opacity: .5;
}

.widget-page .widget-btn {
  //right: -($grid-gutter-width / 2);
}

.widget-content-editing {
  min-height: 100px;
  padding-top: 2.3rem;
  background-color: $bg;
  text-align: center;
}

.ideafactory-info {
}


// First level widgets - .ideafactory-info .card .card

.ideafactory-info .card .card {
  margin-bottom: 1.8rem;
}

.ideafactory-info .card .card .card-header {
  background-color: $secondary;
  padding-bottom: 0.6rem;
  padding-top: 0.6rem;
}

.ideafactory-info .card .card .card-header h2,
.ideafactory-info .card .card .card-header h2 a {
  color: white;
}

.ideafactory-info .card .card .card-header h2 {
  margin-bottom: 0;
  @extend .font-size-18;
}

.ideafactory-info .card .card .card-body {
  background-color: $bg;
  // border: 1px solid red;
}


// Second level widgets - .ideafactory-info .card .card .card

.ideafactory-info .card .card .card {
  margin-bottom: 0;
}

.ideafactory-info .card .card .card .card-header {
  background-color: $bg;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.ideafactory-info .card .card .card .card-header h2,
.ideafactory-info .card .card .card .card-header h2 a {
  color: $secondary;
  @extend .font-size-16;
  font-family: $font-family-base;
  text-align: left;
}

.ideafactory-info .card .card .card .card-header h2 {
  padding-top: .2rem;
  padding-bottom: 0rem;

}

.ideafactory-info .card .card .card .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}

.row--sortable:before {
  content: ' ';
  display: block;
  height: 1rem;
  width: 100%;
}

.row--sortable:after {
  content: ' ';
  display: block;
  height: 1rem;
  width: 100%;
}
